import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 940.000000 788.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,788.000000) scale(0.100000,-0.100000)">
					<path d="M5795 6744 c-41 -21 -65 -53 -65 -90 0 -55 22 -64 166 -68 147 -3
224 -20 329 -72 180 -88 299 -234 354 -434 16 -57 21 -104 21 -202 0 -126 0
-127 27 -148 50 -39 123 -4 143 69 14 52 12 166 -5 267 -35 201 -98 325 -234
459 -161 158 -359 235 -605 235 -71 0 -110 -5 -131 -16z"/>
					<path d="M5773 6440 c-42 -13 -73 -54 -73 -96 0 -50 28 -64 125 -64 99 -1 152
-12 232 -52 152 -75 243 -238 243 -438 0 -80 17 -110 62 -110 21 0 40 10 64
34 l34 34 0 99 c0 275 -148 487 -397 568 -87 29 -236 41 -290 25z"/>
					<path d="M2500 4782 c0 -1750 -4 -1638 63 -1772 40 -80 142 -190 216 -234 31
-18 85 -43 121 -57 l65 -24 788 -3 788 -3 -44 54 c-57 71 -113 164 -154 256
l-32 76 -649 5 -648 5 -41 27 c-25 17 -49 45 -64 75 l-24 48 0 1375 0 1375
1162 3 1163 2 2 -687 c3 -606 5 -688 18 -684 8 2 95 6 193 9 l177 4 0 289 0
289 195 0 195 0 0 -290 0 -290 193 2 192 3 0 480 0 480 -387 3 -388 2 0 385 0
385 -1550 0 -1550 0 0 -1588z"/>
					<path d="M5730 6140 c-35 -15 -60 -50 -60 -86 0 -43 27 -64 81 -64 147 0 250
-98 250 -236 0 -89 43 -123 109 -89 49 26 65 82 51 180 -17 120 -92 224 -195
271 -61 28 -194 41 -236 24z"/>
					<path d="M5685 5792 c-57 -48 -69 -101 -34 -146 31 -39 94 -37 137 5 68 67 47
152 -39 156 -30 2 -50 -3 -64 -15z"/>
					<path d="M3270 5310 l0 -290 778 0 777 0 -2 278 c-1 152 -2 283 -2 290 -1 9
-163 12 -776 12 l-775 0 0 -290z"/>
					<path d="M3274 4617 c-2 -7 -3 -94 -2 -192 l3 -180 628 -3 628 -2 77 71 c42
38 108 91 146 117 l70 47 -2 70 c0 39 -1 73 -1 78 -1 4 -348 7 -771 7 -618 0
-772 -3 -776 -13z"/>
					<path d="M5547 4423 c-7 -7 -10 -813 -3 -813 3 0 63 59 134 130 71 72 135 130
141 130 19 0 97 -115 124 -182 99 -248 24 -525 -185 -683 -101 -76 -190 -108
-319 -113 -131 -5 -198 8 -298 59 -124 64 -231 184 -282 318 -27 69 -37 239
-20 322 15 71 62 170 113 238 l40 53 -135 136 -136 136 -36 -39 c-95 -107
-178 -261 -215 -400 -27 -103 -37 -284 -21 -395 51 -349 304 -652 638 -766
119 -40 199 -54 318 -54 471 0 862 325 956 795 15 78 15 263 0 340 -35 171
-98 310 -195 432 -36 46 -66 86 -66 89 0 3 48 54 108 113 59 60 118 120 131
135 l24 26 -405 0 c-222 0 -408 -3 -411 -7z"/>
					<path d="M3277 4043 c-4 -3 -7 -91 -7 -195 l0 -188 495 0 495 0 5 28 c18 86
52 188 90 270 l44 92 -558 0 c-307 0 -561 -3 -564 -7z"/>
					<path d="M3302 2382 c-77 -24 -124 -108 -137 -244 l-7 -78 81 0 81 0 1 58 c0
76 18 126 46 130 41 6 53 -30 53 -166 0 -117 -1 -124 -25 -147 -16 -17 -35
-25 -60 -25 l-35 0 0 -68 0 -69 47 -9 c74 -14 87 -41 91 -178 4 -134 -10 -186
-52 -186 -34 0 -49 30 -55 115 l-6 70 -79 3 -79 3 6 -73 c7 -89 20 -135 50
-178 39 -58 70 -74 150 -78 63 -3 77 -1 107 20 76 52 114 151 113 293 -1 108
-22 193 -58 241 l-27 36 26 51 c31 62 46 136 46 226 0 112 -36 197 -101 235
-33 20 -138 30 -177 18z"/>
					<path d="M2590 1825 l0 -555 75 0 75 0 0 317 c0 177 4 313 9 307 8 -8 93 -309
148 -526 l25 -98 74 0 74 0 0 555 0 555 -75 0 -75 0 0 -317 c0 -180 -4 -313
-9 -307 -7 7 -152 524 -168 602 -5 20 -11 22 -79 22 l-74 0 0 -555z"/>
					<path d="M3640 2300 l0 -80 80 0 80 0 0 -475 0 -475 80 0 80 0 0 475 0 475 75
0 75 0 0 80 0 80 -235 0 -235 0 0 -80z"/>
					<path d="M4180 1823 l0 -556 131 6 c149 6 202 25 251 87 49 63 60 129 65 403
11 537 -32 617 -334 617 l-113 0 0 -557z m232 386 c43 -23 48 -60 48 -372 0
-162 -3 -308 -6 -324 -9 -45 -36 -73 -77 -80 l-37 -6 0 397 0 396 26 0 c14 0
34 -5 46 -11z"/>
					<path d="M4866 2363 c-6 -18 -11 -50 -91 -583 -24 -162 -51 -340 -60 -395 -8
-55 -15 -104 -15 -108 0 -4 35 -7 78 -5 l77 3 14 113 14 113 81 -3 81 -3 7
-50 c4 -27 10 -77 15 -110 l8 -60 79 -3 78 -3 -6 33 c-3 18 -19 125 -36 238
-30 199 -73 485 -105 700 -23 154 -11 140 -120 140 -80 0 -94 -2 -99 -17z
m129 -478 c9 -72 19 -149 22 -172 l6 -43 -57 0 c-41 0 -56 4 -56 14 0 22 39
319 46 346 6 24 7 24 14 5 4 -11 15 -79 25 -150z"/>
					<path d="M5250 2300 l0 -80 80 0 80 0 0 -475 0 -475 80 0 80 0 0 475 0 475 75
0 75 0 0 80 0 80 -235 0 -235 0 0 -80z"/>
					<path d="M5898 2273 c-10 -60 -31 -198 -47 -308 -17 -110 -46 -304 -66 -432
-19 -127 -35 -239 -35 -247 0 -13 13 -16 74 -16 85 0 75 -15 97 143 l12 87 79
0 c54 0 80 -4 82 -12 2 -7 10 -59 17 -115 l12 -103 79 0 80 0 -6 28 c-6 30
-51 323 -141 932 -25 168 -10 150 -123 150 l-98 0 -16 -107z m126 -255 c7 -44
46 -332 46 -341 0 -4 -25 -7 -55 -7 -66 0 -64 -9 -35 190 11 74 20 147 20 163
0 38 18 34 24 -5z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
